import React, { useState } from "react";
import axios from "axios";

const TestForm = () => {
  const [customerId, setCustomerId] = useState(36);
  const [customerData, setCustomerData] = useState(null); // 서버에서 조회한 데이터
  const [tmData, setTmData] = useState([]); // TM 데이터
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // 데이터 조회 (GET 요청)
  const fetchCustomerData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`/api/tm-call/${customerId}`);
      setCustomerData(response.data);
      setTmData(response.data.tm_data || []); // TM 데이터 설정
    } catch (err) {
      setError("데이터를 가져오지 못했습니다.");
    } finally {
      setLoading(false);
    }
  };

  // TM 데이터 수정 핸들러
  const handleTmDataChange = (index, field, value) => {
    setTmData((prevTmData) =>
      prevTmData.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      )
    );
  };

  // 데이터 PUT 요청 (TM 데이터 업데이트)
  const updateCustomerData = async () => {
    try {
      await axios.put(`/api/tm-call/${customerId}`, { tm_data: tmData });
      alert("데이터가 성공적으로 업데이트되었습니다.");
      fetchCustomerData(); // 업데이트 후 데이터 재조회
    } catch (err) {
      setError("데이터 업데이트에 실패했습니다.");
    }
  };

  return (
    <div className="tm-call">
      {/* ID 입력 및 조회 */}
      <div className="row">
        <label>고객 ID</label>
        <input
          type="text"
          value={customerId}
          onChange={(e) => setCustomerId(e.target.value)}
        />
        {/* <button onClick={fetchCustomerData}>데이터 조회</button> */}
      </div>

      {/* 로딩 및 에러 표시 */}
      {loading && <p>Loading...</p>}
      {error && <p className="error-message">{error}</p>}

      {/* 데이터 표시 */}
      {customerData && (
        <>
          <div className="customer-details">
            <h3>고객 기본 정보</h3>
            <p>
              <strong>이름:</strong> {customerData.name}
            </p>
            <p>
              <strong>전화번호:</strong> {customerData.phone}
            </p>
            <p>
              <strong>설문:</strong> {customerData.customer_option}
            </p>
            <p>
              <strong>신청일:</strong>{" "}
              {customerData.date
                ? new Date(customerData.date).toLocaleDateString()
                : "N/A"}
            </p>
            <p>
              <strong>병원 이름:</strong> {customerData.hospital_name || "N/A"}
            </p>
            <p>
              <strong>광고사 이름:</strong>{" "}
              {customerData.advertising_company_name || "N/A"}
            </p>
            <p>
              <strong>이벤트 이름:</strong> {customerData.event_name || "N/A"}
            </p>
          </div>

          {/* TM 데이터 수정 가능 */}
          <div className="tm-data">
            <h3>TM 데이터 수정</h3>
            {tmData.length > 0 ? (
              tmData.map((tm, index) => (
                <div className="tm-row" key={tm.id}>
                  <div className="input-group">
                    <label>예약자 이름</label>
                    <input
                      type="text"
                      value={tm.reserver_name || ""}
                      onChange={(e) =>
                        handleTmDataChange(
                          index,
                          "reserver_name",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className="input-group">
                    <label>예약자 번호</label>
                    <input
                      type="text"
                      value={tm.reserver_phone || ""}
                      onChange={(e) =>
                        handleTmDataChange(
                          index,
                          "reserver_phone",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className="input-group">
                    <label>콜 내역</label>
                    <select
                      value={tm.call_history || ""}
                      onChange={(e) =>
                        handleTmDataChange(
                          index,
                          "call_history",
                          e.target.value
                        )
                      }
                    >
                      {[
                        "예약",
                        "부재",
                        "본인아님",
                        "취소요청",
                        "당일재통화",
                        "재통화요청",
                        "본인연락",
                        "중복",
                        "치과전달",
                        "장기부재",
                        "잠재고객",
                        "구환",
                      ].map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="input-group">
                    <label>콜 차수</label>
                    <select
                      value={tm.call_count || ""}
                      onChange={(e) =>
                        handleTmDataChange(index, "call_count", e.target.value)
                      }
                    >
                      {[1, 2, 3, 4, 5, 6, 7].map((option) => (
                        <option key={option} value={option}>
                          {option}차
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="input-group">
                    <label>예약 날짜</label>
                    <input
                      type="date"
                      value={
                        tm.reservation_date
                          ? tm.reservation_date.split("T")[0]
                          : ""
                      }
                      onChange={(e) =>
                        handleTmDataChange(
                          index,
                          "reservation_date",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className="input-group">
                    <label>결제일</label>
                    <input
                      type="date"
                      value={
                        tm.payment_date ? tm.payment_date.split("T")[0] : ""
                      }
                      onChange={(e) =>
                        handleTmDataChange(
                          index,
                          "payment_date",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className="input-group">
                    <label>방문 상태</label>
                    <select
                      value={tm.visit_status || ""}
                      onChange={(e) =>
                        handleTmDataChange(
                          index,
                          "visit_status",
                          e.target.value
                        )
                      }
                    >
                      {[
                        "결제",
                        "상담",
                        "노쇼",
                        "재통화요청",
                        "개인일정취소",
                        "상담종료",
                        "재예약",
                      ].map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="input-group">
                    <label>이벤트 메모</label>
                    <textarea
                      value={tm.event_memo || ""}
                      onChange={(e) =>
                        handleTmDataChange(index, "event_memo", e.target.value)
                      }
                    />
                  </div>
                  <div className="input-group">
                    <label>콜 메모</label>
                    <textarea
                      value={tm.call_memo || ""}
                      onChange={(e) =>
                        handleTmDataChange(index, "call_memo", e.target.value)
                      }
                    />
                  </div>
                  <div className="input-group">
                    <label>재통화 시간</label>
                    <input
                      type="datetime-local"
                      value={
                        tm.follow_up_time
                          ? tm.follow_up_time.replace("Z", "")
                          : ""
                      }
                      onChange={(e) =>
                        handleTmDataChange(
                          index,
                          "follow_up_time",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className="input-group">
                    <label>총 결제 예정 금액</label>
                    <input
                      type="number"
                      step="0.01"
                      value={tm.total_payment || ""}
                      onChange={(e) =>
                        handleTmDataChange(
                          index,
                          "total_payment",
                          parseFloat(e.target.value) || null
                        )
                      }
                    />
                  </div>
                  <div className="input-group">
                    <label>상담 금액</label>
                    <input
                      type="number"
                      step="0.01"
                      value={tm.consultation_fee || ""}
                      onChange={(e) =>
                        handleTmDataChange(
                          index,
                          "consultation_fee",
                          parseFloat(e.target.value) || null
                        )
                      }
                    />
                  </div>
                </div>
              ))
            ) : (
              <p>TM 데이터가 없습니다.</p>
            )}
          </div>

          {/* 전송 버튼 */}
          <button className="tm-call-submit-btn" onClick={updateCustomerData}>
            데이터 저장
          </button>
        </>
      )}
    </div>
  );
};

export default TestForm;
