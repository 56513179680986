import React, { useState, useEffect } from "react";
import "./timePicker.scss";
const TimePicker = ({ currentTime, onTimeChange }) => {
  const [hour, setHour] = useState(parseInt(currentTime.split(":")[0]));
  const [minute, setMinute] = useState(parseInt(currentTime.split(":")[1]));
  const [isAM, setIsAM] = useState(hour < 12); // 기본적으로 AM 설정

  const plusIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
    >
      <path
        d="M1.33301 10.381L9.99967 1.71436L18.6663 10.381"
        stroke="#4880FF"
        stroke-width="2"
      />
    </svg>
  );

  const minusIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
    >
      <path
        d="M18.667 1.66659L10.0003 10.3333L1.33366 1.66659"
        stroke="#4880FF"
        stroke-width="2"
      />
    </svg>
  );

  useEffect(() => {
    setHour(parseInt(currentTime.split(":")[0]));
    setMinute(parseInt(currentTime.split(":")[1]));
  }, [currentTime]);

  const increaseHour = () => {
    setHour((prev) => (prev === 12 ? 1 : prev + 1));
  };

  const decreaseHour = () => {
    setHour((prev) => (prev === 1 ? 12 : prev - 1));
  };

  const increaseMinute = () => {
    setMinute((prev) => (prev === 59 ? 0 : prev + 1));
  };

  const decreaseMinute = () => {
    setMinute((prev) => (prev === 0 ? 59 : prev - 1));
  };

  const toggleAMPM = () => {
    setIsAM((prevIsAM) => !prevIsAM);
  };

  // 입력값이 변경될 때 호출되는 함수
  const handleHourChange = (e) => {
    let newHour = e.target.value;

    // 숫자가 아니거나 빈 값일 경우 처리
    newHour = newHour.replace(/[^0-9]/g, "");

    // 0 ~ 12 사이의 값으로 제한
    if (newHour !== "") {
      const numValue = Math.max(0, Math.min(12, Number(newHour)));
      newHour = numValue.toString().padStart(2, "0"); // 두 자리수 강제
    }
    setHour(newHour);
  };
  // 입력값이 변경될 때 호출되는 함수
  const handleMinuteChange = (e) => {
    let newMinute = e.target.value;

    // 숫자가 아니거나 빈 값일 경우 처리
    newMinute = newMinute.replace(/[^0-9]/g, "");

    // 0 ~ 59 사이의 값으로 제한
    if (newMinute !== "") {
      const numValue = Math.max(0, Math.min(59, Number(newMinute)));
      newMinute = numValue.toString().padStart(2, "0"); // 두 자리수 강제
    }

    setMinute(newMinute); // 상태 업데이트
  };

  return (
    <div className="time-picker">
      <div>
        <div className="time-unit">
          <button onClick={decreaseHour}>{plusIcon}</button>
          <input
            type="number"
            value={hour}
            min="0"
            onChange={handleHourChange} // 값 변경 처리
          />
          <button onClick={increaseHour}>{minusIcon}</button>
        </div>

        <span>:</span>
        <div className="time-unit">
          <button onClick={decreaseMinute}>{plusIcon}</button>
          <input
            type="number"
            value={minute}
            onChange={handleMinuteChange} // 값 변경 처리
          />
          <button onClick={increaseMinute}>{minusIcon}</button>
        </div>
        <div className="ampm">
          <button onClick={toggleAMPM}>{plusIcon}</button>
          <span>{isAM ? "AM" : "PM"}</span>
          <button onClick={toggleAMPM}>{minusIcon}</button>
        </div>
      </div>
    </div>
  );
};

export default TimePicker;
