// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "./components/NotFound.jsx";
import CodeGenerator from "./pages/code/CodeGenerator.jsx";
import CodePage from "./pages/code/CodePage.jsx";

import Layout from "./layout/Layout.jsx";

// tm
import NoticeBoard from "./pages/tm/NoticeBoard.jsx";
import NoticeDetail from "./pages/tm/NoticeDetail.jsx";
// admin
import TabComponent from "./pages/admin/listsetting/TabComponent.jsx";
import UserManagement from "./pages/admin/user/UserManagement.jsx";
import Blacklist from "./pages/admin/blacklist/Blacklist.jsx";
import UrlCodeMetrics from "./pages/admin/metrics/UrlCodeMetrics.jsx";
// login
import Login from "./pages/login/Login.jsx";
//
import CustomerDataPage from "./pages/customorDataPage/CustomorDataPage.jsx";
import ExpenseInputForm from "./pages/advertisingExpenses/ExpenseInput.jsx";
import AdvertisingExpenses from "./pages/advertisingExpenses/AdvertisingExpenses.jsx";
import AdExpenseStatus from "./pages/advertisingExpenses/AdExpenseStatus.jsx";

import Wonho from "./pages/advertisingExpenses/Wonho.jsx";
import RevenueAndCostSetting from "./pages/revenueAndCostSetting/RevenueAndCostSetting.jsx";
import TestForm from "./pages/tmCall/TestForm.jsx";
import Calendar from "./pages/tmCall/Calendar.jsx";
import CustomerDetail from "./pages/tmCall/CustomerDetail.jsx";

function App() {
  return (
    <div className="App">
      <Router>
        <Layout>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/code-generator" element={<CodeGenerator />} />
            <Route path="/:code/:urlCode" element={<CodePage />} />
            {/* URL 코드에 따른 페이지 */}
            <Route
              path="/db"
              element={
                <CustomerDataPage title="접수자 리스트" get_status={1} />
              }
            />
            <Route
              path="/trashCanData"
              element={<CustomerDataPage title="휴지통" get_status={0} />}
            />
            {/* 광고비관리 */}
            <Route path="/ad-cost/input" element={<AdvertisingExpenses />} />
            <Route path="/ad-cost/monitor" element={<AdExpenseStatus />} />
            {/* TM - 공지 */}
            <Route path="/tm/notices" element={<NoticeBoard />} />
            <Route path="/tm/notices/new" element={<NoticeDetail />} />
            <Route path="/tm/notices/:id" element={<NoticeDetail />} />
            {/* TM - call */}
            <Route path="/tmcall/CustomerDetail" element={<CustomerDetail />} />
            CustomerDetail
            {/* 관리자 */}
            <Route path="/admin/listsetting" element={<TabComponent />} />
            <Route path="/admin/UserManagement" element={<UserManagement />} />
            <Route path="/admin/metrics" element={<UrlCodeMetrics />} />
            <Route path="/admin/revenue" element={<RevenueAndCostSetting />} />
            <Route path="/admin/blacklist" element={<Blacklist />} />
            <Route path="/Wonho" element={<TestForm />} />
            <Route path="/Wonho2" element={<Calendar />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
