import React, { useState } from "react";
import axios from "axios";

const Calendar = () => {
  const [hospitalNameId, setHospitalNameId] = useState(3005);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1); // 월은 0부터 시작하므로 +1
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // API 호출 함수
  const fetchReservations = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get("/api/tm-call/reservations", {
        params: { hospital_name_id: hospitalNameId, year, month },
      });
      setReservations(response.data); // API 응답 데이터 설정
    } catch (err) {
      console.error(err);
      setError("예약 데이터를 가져오는 데 실패했습니다.");
    } finally {
      setLoading(false);
    }
  };

  // 달력 생성 함수
  const generateCalendar = () => {
    const firstDayOfMonth = new Date(year, month - 1, 1); // 월은 0부터 시작
    const lastDayOfMonth = new Date(year, month, 0); // 다음 달의 0일은 현재 달의 마지막 날
    const calendarDays = [];
    const startDay = firstDayOfMonth.getDay(); // 해당 월의 시작 요일 (0: 일요일)
    const totalDays = lastDayOfMonth.getDate(); // 해당 월의 총 일수

    // 이전 달 공백 채우기
    for (let i = 0; i < startDay; i++) {
      calendarDays.push(null);
    }

    // 해당 월의 날짜 추가
    for (let day = 1; day <= totalDays; day++) {
      calendarDays.push(day);
    }

    return calendarDays;
  };

  const calendarDays = generateCalendar();

  // 예약 데이터 필터링 함수
  const getReservationsForDate = (date) => {
    return reservations.filter(
      (res) => new Date(res.reservation_date).getDate() === date
    );
  };

  // 조회 버튼 클릭 핸들러
  const handleFetch = () => {
    if (!hospitalNameId) {
      setError("병원 ID를 입력하세요.");
      return;
    }
    fetchReservations();
  };

  return (
    <div className="test-calendar-container">
      <div className="test-calendar-filters">
        <div className="test-calendar-input-group">
          <label>병원 ID</label>
          <input
            type="text"
            value={hospitalNameId}
            onChange={(e) => setHospitalNameId(e.target.value)}
            placeholder="병원 ID를 입력하세요"
          />
        </div>
        <div className="test-calendar-input-group">
          <label>연도</label>
          <input
            type="number"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            min="2000"
            max="2100"
          />
        </div>
        <div className="test-calendar-input-group">
          <label>월</label>
          <input
            type="number"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            min="1"
            max="12"
          />
        </div>
        <button className="test-calendar-fetch-button" onClick={handleFetch}>
          조회
        </button>
      </div>

      {loading && <p className="test-calendar-loading">Loading...</p>}
      {error && <p className="test-calendar-error-message">{error}</p>}

      <div className="test-calendar">
        <div className="test-calendar-header">
          {["일", "월", "화", "수", "목", "금", "토"].map((day) => (
            <div key={day} className="test-calendar-day">
              {day}
            </div>
          ))}
        </div>
        <div className="test-calendar-grid">
          {calendarDays.map((day, index) => (
            <div key={index} className="test-calendar-cell">
              {day && (
                <>
                  <div className="test-calendar-date">{day}</div>
                  <div className="test-calendar-reservation-list">
                    {getReservationsForDate(day).map((res) => (
                      <div
                        key={res.id}
                        className="test-calendar-reservation-item"
                      >
                        {/* 시간 (윗줄) */}
                        <div className="test-calendar-reservation-time">
                          {res.reservation_date.slice(11, 16)}{" "}
                          {/* 14:00처럼 시간만 추출 */}
                        </div>
                        {/* 예약자 이름과 광고 제목 (아랫줄) */}
                        <div className="test-calendar-reservation-details">
                          {res.reserver_name} / {res.ad_title}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      <style jsx>{`
        .test-calendar-container {
          padding: 20px;
          font-family: "Arial", sans-serif;
        }
        .test-calendar-filters {
          display: flex;
          gap: 10px;
          margin-bottom: 20px;
        }
        .test-calendar-input-group {
          display: flex;
          flex-direction: column;
        }
        .test-calendar-input-group label {
          margin-bottom: 5px;
        }
        .test-calendar-input-group input {
          padding: 5px;
          font-size: 14px;
          border: 1px solid #ccc;
          border-radius: 5px;
        }
        .test-calendar-fetch-button {
          padding: 10px 15px;
          font-size: 14px;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
        .test-calendar-fetch-button:hover {
          background-color: #0056b3;
        }
        .test-calendar {
          display: grid;
          grid-template-rows: auto 1fr;
          gap: 10px;
        }
        .test-calendar-header {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          background-color: #007bff;
          color: white;
          padding: 10px 0;
          text-align: center;
        }
        .test-calendar-grid {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          gap: 5px;
        }
        .test-calendar-cell {
          border: 1px solid #ccc;
          border-radius: 5px;
          padding: 10px;
          min-height: 80px;
          background-color: #f9f9f9;
          position: relative;
        }
        .test-calendar-date {
          font-weight: bold;
          margin-bottom: 5px;
        }
        .test-calendar-reservation-list {
          font-size: 12px;
          color: #333;
        }
        .test-calendar-reservation-item {
          background-color: #f0f8ff;
          border-radius: 3px;
          padding: 5px;
          margin-bottom: 5px;
        }
        .test-calendar-reservation-time {
          font-size: 14px;
          font-weight: bold;
          color: #007bff;
          margin-bottom: 3px;
        }
        .test-calendar-reservation-details {
          font-size: 12px;
          color: #333;
        }
      `}</style>
    </div>
  );
};

export default Calendar;
