import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import axios from "axios";
import FilterComponent from "./FilterComponent";
import AccordionComponent from "./AccordionComponent";
import StatCard from "./StatCard";
import DeleteButton from "./DeleteButton";
import "./customerdata.scss";
import DuplicateModal from "./DuplicateModal";
import ExcelTemp from "./ExcelTemp";
import DeleteBox from "../admin/listsetting/DeleteBox";

function CustomerDataPage({ title, get_status }) {
  const location = useLocation(); // location 객체를 사용하여 경로 접근

  const [datePickerStatus, setDatePickerStatus] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [excelCustomers, setExcelCustomers] = useState([]);

  const [editState, setEditState] = useState({});
  // 중복 모달 상태 관리
  const [isDuplicateModal, setIsDuplicateModal] = useState(false);
  const [isDuplicateValue, setIsDuplicateValue] = useState("");
  // 삭제 모달 상태 관리
  const [showDeleteBox, setShowDeleteBox] = useState(false); // 모달 표시 여부
  const [deleteBoxMessage, setDeleteBoxMessage] = useState(""); // 모달 메시지
  const [pendingStatus, setPendingStatus] = useState(null); // 확인 중인 상태

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [totalPages, setTotalPages] = useState(1); // 전체 페이지 수
  const [totalCounts, setTotalCounts] = useState([]); // 전체 페이지 수
  const limit = 10; // 한 페이지에 보여줄 데이터 수
  const [recentSettings, setRecentSettings] = useState([]);
  const [checkedCompanies, setCheckedCompanies] = useState(""); // 체크된 회사 목록
  const [companyOptions, setCompanyOptions] = useState([]); // 회사 필터 옵션
  // 날짜 관련
  const [customDateRange, setCustomDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  // 병원 관련
  const [selectedHospital, setSelectedHospital] = useState(undefined); // 초기 상태를 undefined로 설정
  const [hospitalOptions, setHospitalOptions] = useState([]); // get 으로 받은 병원들의 데이터

  // 회사 필터 상태 --------------------------------------------
  const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] = useState(false); // 회사 필터 열림/닫힘 상태
  const [newCompany, setNewCompany] = useState("");
  // 아코디언에서 보내는 요청인 경우
  const [accordionComponent, setAccordionComponent] = useState(null);
  // 메트릭스 데이터
  const [metricData, setMetricData] = useState({ conversionRates: [] });

  const closeCompanyDropdown = () => {
    if (isCompanyDropdownOpen) {
      setIsCompanyDropdownOpen(false);
    }
  };

  // urlCodeTitleID 별 필터 (아코디언의 버튼 클릭 시)
  const [urlCodeId, setUrlCodeId] = useState(""); // "" 추가했음 1월 20일
  // filters 상태를 CustomerDataPage에서 관리
  const [filters, setFilters] = useState({
    url_code: "",
  });

  const duplicateModalHandler = (value) => {
    setIsDuplicateValue(value);
    setIsDuplicateModal(!isDuplicateModal);
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    fetchData(filters); // currentPage가 변경될 때마다 데이터 가져오기
    if (currentPage == 1) {
      metricsData();
    }
  }, [
    currentPage,
    filters,
    checkedCompanies,
    customDateRange,
    newCompany,
    urlCodeId,
    location.pathname,
  ]);

  useEffect(() => {
    if (urlCodeId) {
      setNewCompany(""); // urlCodeId가 변경되면 newCompany를 초기화
    }
  }, [urlCodeId]);

  // newCompany가 변경되면 urlCodeId를 빈 문자열로 설정
  useEffect(() => {
    if (newCompany) {
      setUrlCodeId(""); // newCompany가 변경되면 urlCodeId를 초기화
    }
  }, [newCompany]);

  const adjustDateForKST = (date) => {
    const localDate = new Date(date);
    localDate.setHours(localDate.getHours() + 9);
    return localDate.toISOString();
  };

  // 엑셀 데이터 받는 코드
  const excelFetchData = async (filters = {}) => {
    const startDate = adjustDateForKST(customDateRange.startDate);
    const endDate = adjustDateForKST(customDateRange.endDate);

    console.log(filters);
    try {
      const response = await axios.post("/api/customer/excel", {
        data_status: get_status,
        advertising_company_ids: checkedCompanies,
        newCompany: newCompany && newCompany,
        startDate: startDate,
        endDate: endDate,
        url_code_setting_id: urlCodeId,
        accordionComponent: accordionComponent !== null && accordionComponent,
        ...filters,
      });
      return response.data.data; // 데이터를 직접 반환
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("데이터를 불러오는 중 오류가 발생했습니다.");
      setLoading(false);
      return []; // 에러 시 빈 배열 반환
    }
  };

  const fetchData = async (filters = {}) => {
    setLoading(true);

    // 예시 사용
    const startDate = adjustDateForKST(customDateRange.startDate);
    const endDate = adjustDateForKST(customDateRange.endDate);

    try {
      const response = await axios.post("/api/customer/search", {
        data_status: get_status,
        page: currentPage,
        limit,
        advertising_company_ids: checkedCompanies,
        newCompany: newCompany && newCompany,
        startDate: startDate, // 날짜 필터 변환된 형식
        endDate: endDate, // 날짜 필터 변환된 형식
        url_code_setting_id: urlCodeId, // urlCodeId 추가
        accordionComponent: accordionComponent !== null && accordionComponent,
        ...filters,
      });

      setTotalCounts(response.data.total);
      setTotalPages(Math.ceil(response.data.total.totalCount / limit));
      setRecentSettings(response.data.recentSettings);

      if (accordionComponent !== 1) {
        setUrlCodeId("");
      }
      if (response.data.total.totalCount > 0) {
        setCustomers(
          response.data.data.map((customer) => {
            return {
              ...customer,
              isSelected: false,
            };
          })
        );

        const initialState = {};
        response.data.data.forEach((item) => {
          initialState[item.id] = false;
        });
        setEditState(initialState);
        console.log(response.data);
      } else {
        // total이 0일 경우
        setCustomers([]); // 빈 배열로 설정
        setTotalPages(1); // 페이지를 1로 설정
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("데이터를 불러오는 중 오류가 발생했습니다.");
      setLoading(false);
    }
  };

  const metricsData = async () => {
    // 날짜 조정 예시 사용

    const startDate = adjustDateForKST(customDateRange.startDate);
    const endDate = adjustDateForKST(customDateRange.endDate);
    if (checkedCompanies !== "") {
      try {
        const response = await axios.post("/api/customer/metrics", {
          startDate: startDate, // 날짜 필터 변환된 형식
          endDate: endDate, // 날짜 필터 변환된 형식
        });
        setMetricData(response.data); // 데이터 설정
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("데이터를 불러오는 중 오류가 발생했습니다.");
      }
    }
  };

  const dataFilterClean = () => {
    setNewCompany(""); // 회사 필드 초기화
    if (accordionComponent !== 1) {
      setUrlCodeId("");
    }
    // setFilters((prevFilters) => ({
    //   ...prevFilters, // 기존 필터 값 유지
    //   selected_hospital_id: "", // 병원 ID 초기화
    //   hospital_name: "", // 병원 이름 초기
    // }));
  };

  // 매체 필터링 버튼 클릭 핸들러
  const handleMediaFilter = (company) => {
    const updatedFilters = { ...filters, advertising_company: company };
    setFilters(updatedFilters);
    fetchData(updatedFilters);
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };
  const handleApplyFilters = () => {
    fetchData(filters);
  };

  const handleCheckboxChange = (index) => {
    setCustomers((prevCustomers) =>
      prevCustomers.map((customer, i) =>
        i === index
          ? { ...customer, isSelected: !customer.isSelected }
          : customer
      )
    );
  };

  const handleEdit = (id) => {
    setEditState((prev) => ({ ...prev, [id]: true }));
  };

  const handleInputChange = (index, field, value) => {
    setCustomers((prevCustomers) =>
      prevCustomers.map((customer, i) =>
        i === index ? { ...customer, [field]: value } : customer
      )
    );
  };

  const handleSubmit = async (index) => {
    const customer = customers[index];
    try {
      const response = await axios.put(
        `/api/customer/${customer.id}`,
        customer
      );
      console.log("Data updated successfully:", response.data);
      setEditState((prev) => ({ ...prev, [customer.id]: false }));
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };
  // 영구 삭제 하면 안됨 put 으로 수정 예정
  const handlePermanentDelete = async () => {
    const selectedIds = customers
      .filter((customer) => customer.isSelected)
      .map((customer) => customer.id);
    if (selectedIds.length > 0) {
      try {
        const response = await axios.delete(`/api/customer/delete`, {
          data: { ids: selectedIds },
        });
        console.log("Permanent delete successful:", response.data);
        alert("선택한 데이터가 영구적으로 삭제되었습니다.");
        await fetchData();
      } catch (error) {
        console.error("Error during permanent deletion:", error);
        alert("영구 삭제 중 오류가 발생했습니다.");
      }
    } else {
      alert("삭제할 데이터를 선택하세요.");
    }
  };
  // 삭제 및 복원 함수 ---------------
  const handleUpdateStatus = async (status) => {
    const selectedIds = customers
      .filter((customer) => customer.isSelected)
      .map((customer) => customer.id);

    if (selectedIds.length === 0) {
      alert("처리할 데이터를 선택하세요.");
      return;
    }

    // 상태별 메시지 정의
    const statusMessage =
      status === 0
        ? "현재 선택한 DB를 삭제하시겠습니까?"
        : status === 1
        ? "현재 선택한 DB를 복원하시겠습니까?"
        : status === 2
        ? "현재 선택한 DB를 정말 삭제하시겠습니까?"
        : "알 수 없는 작업입니다.";

    // 모달 띄우기
    setDeleteBoxMessage(statusMessage);
    setPendingStatus(status);
    setShowDeleteBox(true);
  };

  const confirmUpdateStatus = async () => {
    const selectedIds = customers
      .filter((customer) => customer.isSelected)
      .map((customer) => customer.id);

    try {
      const response = await axios.put(`/api/customer/update-status`, {
        ids: selectedIds,
        data_status: pendingStatus,
      });

      // console.log("Status updated successfully:", response.data);
      // alert(
      //   pendingStatus === 0
      //     ? "선택한 데이터가 삭제되었습니다."
      //     : pendingStatus === 1
      //     ? "선택한 데이터가 복원되었습니다."
      //     : pendingStatus === 2
      //     ? "선택한 데이터가 영구 삭제되었습니다."
      //     : "알 수 없는 작업입니다."
      // );

      await fetchData(filters); // 현재 filters 상태를 유지
    } catch (error) {
      console.error("Error updating status:", error);
      alert(
        pendingStatus === 2
          ? "선택한 데이터 삭제 중 오류가 발생했습니다."
          : "선택한 데이터 복원 중 오류가 발생했습니다."
      );
    } finally {
      setShowDeleteBox(false); // 모달 닫기
      setPendingStatus(null); // 상태 초기화
    }
  };
  const cancelDeleteBox = () => {
    setShowDeleteBox(false);
    setPendingStatus(null);
  };

  // 삭제 및 복원 함수
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // 현재 페이지 초기화
  useEffect(() => {
    setCurrentPage(1);
  }, [checkedCompanies, urlCodeId, filters]);
  //   console.log("checkedCompanies", checkedCompanies);
  //   console.log("companyOptions", companyOptions);
  //   console.log("customDateRange", customDateRange);
  //   console.log("selectedHospital", selectedHospital);

  useEffect(() => {
    setCustomDateRange({
      startDate: new Date(),
      endDate: new Date(),
    });
  }, [location.pathname]);

  function isToday(dateString) {
    // 한국 표준시(KST)를 고려하여 현재 날짜 가져오기
    const now = new Date();
    const koreaNow = new Date(now.getTime() + 9 * 60 * 60 * 1000); // UTC+9
    const today = koreaNow.toISOString().split("T")[0]; // 오늘 날짜 "YYYY-MM-DD" 형식으로 변환

    // 입력된 날짜를 "YYYY-MM-DD"로 변환
    const inputDate = new Date(dateString).toISOString().split("T")[0];

    // 날짜 비교
    return today === inputDate;
  }
  // 전환률 빨간점 표기 미표기기
  const shouldShowRedDot = (customer, metricData) => {
    const matchingMetric = metricData?.conversionRates?.find(
      (metric) => metric.ad_number === customer.ad_number
    );

    return (
      matchingMetric &&
      parseFloat(customer.conversionRate) <=
        (parseFloat(matchingMetric.conversionRate) * 60) / 100
    );
  };

  // console.log(metricData);

  return (
    <div className="DataContainer container">
      {/* <h2>{title}</h2> */}

      {/* filters 상태와 handleFilterChange 함수를 FilterComponent에 전달 */}
      <FilterComponent
        filters={filters}
        onFilterChange={handleFilterChange}
        handleApplyFilters={handleApplyFilters}
        checkedCompanies={checkedCompanies}
        setCheckedCompanies={setCheckedCompanies}
        companyOptions={companyOptions}
        setCompanyOptions={setCompanyOptions}
        // 매체 검색 드롭다운 설정
        isCompanyDropdownOpen={isCompanyDropdownOpen}
        setIsCompanyDropdownOpen={setIsCompanyDropdownOpen}
        closeCompanyDropdown={closeCompanyDropdown}
        customDateRange={customDateRange}
        setCustomDateRange={setCustomDateRange}
        hospitalOptions={hospitalOptions}
        setHospitalOptions={setHospitalOptions}
        selectedHospital={selectedHospital}
        setSelectedHospital={setSelectedHospital}
        // 데이터 픽커 상태
        datePickerStatus={datePickerStatus}
        setDatePickerStatus={setDatePickerStatus}
        // 아코디언상태
        setAccordionComponent={setAccordionComponent}
        get_status={get_status}
        handleUpdateStatus={handleUpdateStatus} // 삭제버튼
      />
      {/* 최근 설정 카드 */}
      {get_status === 1 && (
        <div className="itdependson" onClick={closeCompanyDropdown}>
          <div
            className="userSetCompany cardMarginAdded"
            style={{ position: "relative" }}
          >
            <StatCard
              dataFilterClean={dataFilterClean}
              label="DB 전체"
              value={
                totalCounts && totalCounts.totalCount !== undefined
                  ? totalCounts.totalCount
                  : 0
              }
            />

            {/* 알람 배지 */}
            {metricData && metricData.isPeriodBelowThreshold && (
              <div className="alert-badge">!</div>
            )}
          </div>

          {/* 매체별 갯수 및 리스트 */}
          <div className="userSetCompany cardAllSet">
            {(typeof checkedCompanies === "string"
              ? checkedCompanies.split(",").map((id) => parseInt(id)) // 문자열을 배열로 변환
              : Array.isArray(checkedCompanies)
              ? checkedCompanies // 이미 배열인 경우 그대로 사용
              : []
            ) // 배열도 아니고 문자열도 아니면 빈 배열
              .map((companyId) => {
                // companyOptions에서 companyId와 일치하는 매체 찾기
                const company = companyOptions.find(
                  (company) => company.id === companyId
                );

                if (company) {
                  // totalCounts가 정의되어 있는지 확인
                  const countData =
                    totalCounts && totalCounts.countsByCompany
                      ? totalCounts.countsByCompany.find(
                          (count) => count.advertising_company_id === company.id
                        )
                      : null;

                  // countData가 있는 경우, value를 count로 설정하고 없으면 "0"으로 설정
                  const value = countData ? countData.count : 0;

                  return (
                    <StatCard
                      key={company.id}
                      id={company.id}
                      label={company.name}
                      value={value}
                      newCompany={newCompany}
                      setNewCompany={setNewCompany}
                    />
                  );
                }
                return null; // 일치하는 회사가 없으면 아무것도 반환하지 않음
              })}
          </div>
        </div>
      )}
      {get_status === 1 && (
        <div className="recent-settings" onClick={closeCompanyDropdown}>
          <AccordionComponent
            recentSettings={recentSettings}
            setUrlCodeId={setUrlCodeId}
            newCompany={newCompany}
            setAccordionComponent={setAccordionComponent}
          />
        </div>
      )}
      {/* 엑셀, 삭제 버튼 */}
      {get_status === 1 && (
        <div className="button-group" onClick={closeCompanyDropdown}>
          <ExcelTemp
            filters={filters}
            excelCustomers={excelCustomers}
            excelFetchData={excelFetchData}
          />
          <DeleteButton handleUpdateStatus={handleUpdateStatus} />
          {get_status === 0 && (
            <button
              onClick={() => handlePermanentDelete()}
              className="permanent-delete"
            >
              영구삭제
            </button>
          )}
        </div>
      )}

      <table className="customer-table" onClick={closeCompanyDropdown}>
        <thead>
          <tr>
            <th style={{ width: "4%", textAlign: "center", padding: "20px" }}>
              선택
            </th>
            <th style={{ width: "4%", textAlign: "center" }}>No</th>
            <th style={{ width: "6%" }}>배당여부</th>
            <th style={{ width: "5%" }}>병원명</th>
            <th style={{ width: "5%" }}>매체</th>
            <th style={{ width: "15%" }}>광고 제목</th>
            <th style={{ width: "8%" }}>이벤트명</th>
            <th style={{ width: "5%" }}>이름</th>
            <th style={{ width: "8%" }}>번호</th>
            <th style={{ width: "9%" }}>ip</th>
            <th style={{ width: "10%" }}>일자</th>
            <th style={{ width: "4%", textAlign: "center" }}>상태</th>
          </tr>
        </thead>
        {/* 데이터 뿌려주는 부분 */}
        <tbody>
          {totalPages === 0 ? (
            <tr>
              <td colSpan="11">아직 데이터가 없습니다.</td>
            </tr>
          ) : (
            customers.map((customer, index) => (
              <tr
                key={customer.id}
                className={customer.isSelected ? "selected" : ""}
              >
                <td>
                  <input
                    type="checkbox"
                    checked={customer.isSelected}
                    onChange={() => handleCheckboxChange(index)}
                    style={{ textAlign: "center" }}
                  />
                </td>
                <td style={{ textAlign: "center" }}>
                  {totalCounts.totalCount - (currentPage - 1) * limit - index}
                  {customer.date &&
                    get_status === 1 &&
                    isToday(customer.date) && (
                      <span className="newData">new</span>
                    )}
                </td>
                <td>
                  <input
                    type="text"
                    value={customer.dividend_status}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "dividend_status",
                        e.target.value
                      )
                    }
                    disabled={true}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={customer.hospital_name}
                    onChange={(e) =>
                      handleInputChange(index, "hospital_name", e.target.value)
                    }
                    disabled={true}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={customer.advertising_company}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "advertising_company",
                        e.target.value
                      )
                    }
                    disabled={true}
                  />
                </td>
                <td style={{ position: "relative" }}>
                  <input
                    type="text"
                    value={customer.ad_title}
                    onChange={(e) =>
                      handleInputChange(index, "ad_title", e.target.value)
                    }
                    disabled={true}
                  />

                  {/* 붉은 점 표시 */}
                  {shouldShowRedDot(customer, metricData) && (
                    <span className="circleRedAlert"></span>
                  )}
                </td>

                <td>
                  <input
                    type="text"
                    value={customer.event_name}
                    onChange={(e) =>
                      handleInputChange(index, "event_name", e.target.value)
                    }
                    disabled={true}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={customer.name}
                    onChange={(e) =>
                      handleInputChange(index, "name", e.target.value)
                    }
                    disabled={!editState[customer.id]}
                  />
                </td>
                <td
                  style={{
                    textAlign: "center",
                  }}
                >
                  <input
                    style={{
                      textAlign: "center",
                      color: customer.isDuplicatePhone ? "red" : "none",
                      border: "none",
                    }}
                    type="text"
                    value={customer.phone ? customer.phone.split("T")[0] : ""}
                    onChange={(e) =>
                      handleInputChange(index, "phone", e.target.value)
                    }
                    readOnly={!editState[customer.id]} // 수정 방지 상태로 설정
                    onClick={() =>
                      customer.isDuplicatePhone &&
                      duplicateModalHandler(
                        customer.phone ? customer.phone.split("T")[0] : ""
                      )
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={customer.ip}
                    style={{
                      color: customer.isDuplicateIP ? "red" : "none",
                      border: "none",
                    }}
                    readOnly={!editState[customer.id]} // 수정 방지 상태로 설정
                    onClick={() =>
                      customer.isDuplicateIP &&
                      duplicateModalHandler(customer.ip)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={customer.date ? customer.date.split(".")[0] : ""}
                    onChange={(e) =>
                      handleInputChange(index, "date", e.target.value)
                    }
                    disabled={true}
                  />
                </td>
                <td style={{ textAlign: "center" }}>
                  {editState[customer.id] ? (
                    <button
                      className="submit-button"
                      onClick={() => handleSubmit(index)}
                    >
                      저장
                    </button>
                  ) : (
                    <button
                      className="edit-button"
                      onClick={() => handleEdit(customer.id)}
                    >
                      수정
                    </button>
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
        {isDuplicateModal && (
          <DuplicateModal
            duplicateModalHandler={duplicateModalHandler}
            isDuplicateValue={isDuplicateValue}
          />
        )}
        {showDeleteBox && (
          <DeleteBox
            message={deleteBoxMessage}
            pendingStatus={pendingStatus}
            onCancel={cancelDeleteBox}
            onConfirm={confirmUpdateStatus}
          />
        )}
      </table>
      <div className="pagination">
        <button onClick={() => handlePageChange(1)}>
          <img
            src={process.env.PUBLIC_URL + "/images/page/start.png"}
            className="doubleArrow"
            alt="첫 페이지"
          />
        </button>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/page/before.png"}
            className="singleArrow"
            alt="이전 페이지"
          />
        </button>
        {Array.from({ length: totalPages }, (_, index) => index + 1)
          .slice(
            (Math.ceil(currentPage / 10) - 1) * 10,
            Math.ceil(currentPage / 10) * 10
          )
          .map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={page === currentPage ? "currentPage" : ""}
            >
              {page}
            </button>
          ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/page/next.png"}
            className="singleArrow"
            alt="다음 페이지"
          />
        </button>
        <button onClick={() => handlePageChange(totalPages)}>
          <img
            src={process.env.PUBLIC_URL + "/images/page/end.png"}
            className="doubleArrow"
            alt="마지막 페이지"
          />
        </button>
      </div>
    </div>
  );
}

export default CustomerDataPage;
