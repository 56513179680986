import React from "react";

import SideMenu from "./SideMenu";
import Header from "./Header";
import "./layout.scss";

function Layout({ children }) {
  return (
    <div className="layout">
      <Header />
      <div className="main">
        <SideMenu />
        <div className="content">{children}</div>
      </div>
    </div>
  );
}

export default Layout;
