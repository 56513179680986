import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom"; //
import isTokenExpired from "../utils/isTokenExpired"; // 토큰 만료 체크 함수

function Header() {
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = () => {
      const token = localStorage.getItem("token");
      if (!token || isTokenExpired(token)) {
        alert("세션이 만료되었습니다. 다시 로그인해주세요.");
        localStorage.removeItem("token");
        navigate("/login");
      }
    };

    verifyToken();
  }, [navigate]);

  const userId = "관리자";
  const userColor = "#4C61CC";
  const displayUserId = userId.slice(0, 2).toUpperCase();

  const location = useLocation();
  // 로그인 페이지 여부 확인
  const isLoginPage = location.pathname === "/login";

  const handleLogout = () => {
    // localStorage에서 토큰 삭제
    localStorage.removeItem("token");

    // sessionStorage에서 토큰 삭제
    sessionStorage.removeItem("token");

    // 추가적으로 로그아웃 후 이동할 경로를 지정할 수 있습니다.
    navigate("/login"); // 예: 로그인 페이지로 리디렉션
  };

  return (
    <header>
      <div className="logo">
        <span className="gradient-text">Funnel Solution</span>
        <span className="solid-text">Funnel Solution</span>
      </div>

      {!isLoginPage && (
        <div className="profile">
          <div className="sign_out" onClick={handleLogout}>
            <Link to="/login">
              <img src="/images/header/sign-out.png" alt="로그아웃" />
            </Link>
          </div>
          <div className="user-id">
            <span style={{ backgroundColor: userColor }}>{displayUserId}</span>
            <p>{userId}</p>
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;
