import { jwtDecode } from "jwt-decode"; // JWT 디코딩 라이브러리 설치 필요

const isTokenExpired = (token) => {
  if (!token) return true;

  try {
    const { exp } = jwtDecode(token);
    const now = Math.floor(Date.now() / 1000);
    return exp < now;
  } catch (error) {
    console.error("Invalid token:", error);
    return true;
  }
};

export default isTokenExpired; // default로 내보내기
