import React, { useState } from "react";
import axios from "axios";
import TitleBox from "../../components/TitleBox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale"; // 한국어 로케일 추가
import "./customerDetail.scss";
import "./customerDetailDate.css";
import TimePicker from "./TimePicker";
const CustomerDetail = () => {
  const [time, setTime] = useState("11:30");
  const [isTimePickerVisible, setIsTimePickerVisible] = useState(false);

  // timePicker 열기
  const handleOpenTimePicker = () => {
    setIsTimePickerVisible(true);
    console.log(isTimePickerVisible);
  };

  // TimePicker 외부 클릭 시 닫기
  const handleClickOutside = (e) => {
    if (!e.target.closest(".timepickerBox")) {
      setIsTimePickerVisible(false);
    }
  };

  // 시간 값을 변경하는 함수
  const handleTimeChange = (hour, minute, isAM) => {
    const formattedTime = `${hour < 10 ? `0${hour}` : hour}:${
      minute < 10 ? `0${minute}` : minute
    }`;
    setTime(formattedTime);
    setIsTimePickerVisible(false); // 시간 변경 후 TimePicker 숨기기
  };

  // 마운트 시 외부 클릭 감지
  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [customerId, setCustomerId] = useState(36);
  const [customerData, setCustomerData] = useState({
    id: 36,
    url_code_setting_id: 16,
    customer_option: "상담필요",
    phone: "01022224444",
    name: "전환test",
    date: "2024-11-01T12:38:14.000Z",
    hospital_name: "익산",
    advertising_company_name: "GDN(1)",
    event_name: "임플)아이두-59M",
  }); // 서버에서 조회한 데이터

  const [tmData, setTmData] = useState({
    id: 2,
    call_history: "콜 상담 완료",
    call_count: 1,
    reservation_date: "2024-12-25T14:00:00.000Z",
    reserver_name: "홍길동",
    reserver_phone: "010-1234-5678",
    payment_date: "2024-12-20T12:00:00.000Z",
    visit_status: "방문 예정",
    event_memo: "이벤트 메모 수정",
    call_memo: "콜 메모 내용 수정 수정 2",
    follow_up_time: null,
    total_payment: null,
    consultation_fee: null,
  }); // TM 데이터

  const call_history = [
    "예약",
    "부재",
    "본인아님",
    "취소요청",
    "당일재통화",
    "재통화요청",
    "본인연락",
    "중복",
    "치과전달",
    "장기부재",
    "잠재고객",
    "구환",
  ];
  const visit_status = [
    "결제",
    "상담",
    "노쇼",
    "재통화요청",
    "개인일정취소",
    "상담종료",
    "재예약",
  ];

  const call_count = [1, 2, 3, 4, 5, 6, 7];

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // 데이터 조회 (GET 요청)
  const fetchCustomerData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`/api/tm-call/${customerId}`);
      setCustomerData(response.data);
      setTmData(response.data.tm_data || []); // TM 데이터 설정
    } catch (err) {
      setError("데이터를 가져오지 못했습니다.");
    } finally {
      setLoading(false);
    }
  };

  // TM 데이터 수정 핸들러
  // 예시: tmData가 단일 객체인 경우
  const handleTmDataChange = (field, value) => {
    setTmData((prevTmData) => ({
      ...prevTmData,
      [field]: value, // 필드 이름을 동적으로 받아서 해당 값을 수정
    }));
  };

  // 데이터 PUT 요청 (TM 데이터 업데이트)
  const updateCustomerData = async () => {
    try {
      await axios.put(`/api/tm-call/${customerId}`, { tm_data: tmData });
      alert("데이터가 성공적으로 업데이트되었습니다.");
      // fetchCustomerData(); // 업데이트 후 데이터 재조회
    } catch (err) {
      setError("데이터 업데이트에 실패했습니다.");
    }
  };

  return (
    <div className="tmcall_container container_flex">
      <div className="container_left">
        <TitleBox mainmenu="환자별 디테일 페이지" submenu="담당자용" />

        {/* 데이터 표시 */}
        {customerData && (
          <div className="customer_data">
            <div className="inputBox">
              <div>
                <label>신청일</label>
                <input
                  value={
                    customerData.date
                      ? new Date(customerData.date).toLocaleDateString()
                      : "N/A"
                  }
                ></input>
              </div>
              <div>
                <label>지점</label>
                <input
                  value={
                    customerData.date
                      ? new Date(customerData.date).toLocaleDateString()
                      : "N/A"
                  }
                />
              </div>
              <div>
                <label>광고제목</label>
                <input
                  value={
                    customerData.date
                      ? new Date(customerData.date).toLocaleDateString()
                      : "N/A"
                  }
                />
              </div>
              <div>
                <label>설문</label>
                <input
                  value={
                    customerData.date
                      ? new Date(customerData.date).toLocaleDateString()
                      : "N/A"
                  }
                />
              </div>
              <div>
                <label>신청자 이름</label>
                <input
                  value={
                    customerData.date
                      ? new Date(customerData.date).toLocaleDateString()
                      : "N/A"
                  }
                />
              </div>
              <div>
                <label>예약자 이름</label>
                <input
                  value={
                    customerData.date
                      ? new Date(customerData.date).toLocaleDateString()
                      : "N/A"
                  }
                />
                <div className="same_name_checkbox">
                  <label htmlFor="">신청자와 동일</label>
                  <input type="checkbox" />
                </div>
              </div>
              <div>
                <label>전화번호</label>
                <input
                  value={
                    customerData.date
                      ? new Date(customerData.date).toLocaleDateString()
                      : "N/A"
                  }
                />
              </div>
              <div>
                <label>예약자 번호</label>
                <input
                  value={
                    customerData.date
                      ? new Date(customerData.date).toLocaleDateString()
                      : "N/A"
                  }
                />
                <div className="same_name_checkbox">
                  <label htmlFor="">신청자와 동일</label>
                  <input type="checkbox" />
                </div>
              </div>
              <div>
                <label>매체</label>
                <input
                  value={
                    customerData.date
                      ? new Date(customerData.date).toLocaleDateString()
                      : "N/A"
                  }
                />
              </div>
              <div>
                <label>이벤트명</label>
                <input
                  value={
                    customerData.date
                      ? new Date(customerData.date).toLocaleDateString()
                      : "N/A"
                  }
                />
              </div>
            </div>

            <div className="call_history">
              <label>콜 내역</label>
              <div className="btns">
                {call_history.map((option) => (
                  <button key={option} value={option}>
                    {option}
                  </button>
                ))}
              </div>
            </div>

            <div className="call_count">
              <label>콜 차수</label>
              <div className="btns">
                {call_count.map((option) => (
                  <button key={option} value={option}>
                    {option}차
                  </button>
                ))}
              </div>
            </div>

            <div className="dateBox">
              <div>
                <label>예약날짜</label>
                <button className="dateBtn">
                  날짜선택{" "}
                  <input
                    type="date"
                    value={
                      customerData.date
                        ? new Date(customerData.date)
                            .toISOString()
                            .split("T")[0]
                        : "N/A"
                    }
                  />
                </button>
                <div className="timepickerBox" onClick={handleOpenTimePicker}>
                  <p>11:11</p>
                  {isTimePickerVisible && (
                    <TimePicker
                      currentTime={time}
                      onTimeChange={handleTimeChange}
                    />
                  )}
                </div>
              </div>
              <div>
                <label>결제일</label>
                <button className="dateBtn">
                  날짜선택{" "}
                  <input
                    type="date"
                    value={
                      customerData.date
                        ? new Date(customerData.date)
                            .toISOString()
                            .split("T")[0]
                        : "N/A"
                    }
                  />
                </button>
                <div className="timepickerBox" onClick={handleOpenTimePicker}>
                  <p>11:11</p>
                  {isTimePickerVisible && (
                    <TimePicker
                      currentTime={time}
                      onTimeChange={handleTimeChange}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="visit_status">
              <label>방문 상태</label>
              <div className="btns">
                {visit_status.map((option) => (
                  <button key={option} value={option}>
                    {option}
                  </button>
                ))}
              </div>
            </div>

            <div className="memoBox">
              <div>
                <label>이벤트 메모</label>
                <textarea placeholder="이벤트 추가 내용을 입력하세요." />
              </div>
              <div>
                <label>콜메모</label>
                <textarea placeholder="콜 내용을 입력하세요." />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="container_right">
        <div className="tm_data">
          <input className="reserver_name" value={tmData.reserver_name} />
          <div className="datepickerBox">
            <div className="datepickerTitle">재통화 날짜선택</div>
            <DatePicker
              dateFormat="yyyy.MM.dd" // 날짜 형태
              shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
              minDate={new Date("2000-01-01")} // minDate 이전 날짜 선택 불가
              // maxDate={new Date()} // maxDate 이후 날짜 선택 불가
              inline
              locale={ko}
              // selected={selectedDate}
              // onChange={(date) => setSelectedDate(date)}
            />
          </div>
          <div className="inputBox">
            <label>재통화 시간</label>
            <div className="timepickerBox" onClick={handleOpenTimePicker}>
              <p>시간을 입력하세요.</p>
              {isTimePickerVisible && (
                <TimePicker
                  currentTime={time}
                  onTimeChange={handleTimeChange}
                />
              )}
            </div>
          </div>
          <div className="inputBox">
            <label>총 결제 금액</label>

            <input type="text" placeholder="총 결제 예정 금액을 입력하세요" />
          </div>
          <div className="inputBox">
            <label>상담 금액</label>
            <input type="text" placeholder="상담 금액을 입력하세요." />
          </div>
        </div>
        <button className="submitBtn" onClick={updateCustomerData}>
          상담 내용 저장
        </button>
      </div>
    </div>
  );
};

export default CustomerDetail;
