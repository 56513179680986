import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
function SideMenu() {
  const [openIndexes, setOpenIndexes] = useState({});
  const location = useLocation();

  const toggleSubMenu = (index) => {
    setOpenIndexes((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const menuItems = [
    { title: "메인", path: "/" },
    { title: "디비리스트", path: "/db" },
    {
      title: "광고비관리",
      path: "/ad-cost/input",
      subMenu: [
        { title: "광고비입력", path: "/ad-cost/input" },
        { title: "광고비 소진 현황", path: "/ad-cost/monitor" },
      ],
    },
    { title: "성과 리포트", path: "/dd" },
    {
      title: "TM",
      path: "/tm",
      subMenu: [
        { title: "담당자 업무 현황", path: "/tm" },
        { title: "방문 데이터", path: "/tm/1" },
        { title: "예약달력", path: "/tm/2" },
        { title: "공지", path: "/tm/notices" },
      ],
    },
    {
      title: "코드 생성",
      path: "/code-generator",
    },
    {
      title: "관리자 페이지",
      path: "/admin",
      subMenu: [
        { title: "병원/이벤트/매체 리스트", path: "/admin/listsetting" },
        // { title: "지점별 이벤트", path: "/admin/1" },
        { title: "블랙리스트", path: "/admin/blacklist" },
        { title: "계정관리", path: "/admin/UserManagement" },
        { title: "매출 및 실비 설정", path: "/admin/revenue" },
        { title: "디비 개수", path: "/admin/metrics" },
      ],
    },
    { title: "휴지통", path: "/trashCanData" },
  ];

  const isMainMenuActive = (menu) => {
    return (
      location.pathname === menu.path ||
      (menu.subMenu &&
        menu.subMenu.some((subItem) => location.pathname === subItem.path))
    );
  };

  // 로그인 페이지 여부 확인
  const isLoginPage = location.pathname === "/login";

  if (isLoginPage) return null; // 로그인 페이지면 SideMenu를 렌더링하지 않음

  return (
    <nav>
      <ul className="main_menu">
        {menuItems.map((item, index) => {
          const isActive = isMainMenuActive(item);

          return (
            <li key={index}>
              <Link
                to={item.path}
                onClick={() => {
                  if (item.subMenu) toggleSubMenu(index);
                }}
                style={{ color: isActive ? "#77c2ff" : "white" }}
              >
                {item.title}
              </Link>
              {item.subMenu && (
                <ul
                  className="sub_menu"
                  style={{ display: openIndexes[index] ? "none" : "block" }}
                >
                  {item.subMenu.map((subItem, subIndex) => {
                    const isSubActive = location.pathname === subItem.path;
                    return (
                      <li key={subIndex}>
                        <Link
                          to={subItem.path}
                          style={{ color: isSubActive ? "#77c2ff" : "white" }}
                        >
                          {subItem.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default SideMenu;
